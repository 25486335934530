export const {
    NETWORK_CHAIN_ID,
    BETTING_CONTRACT_ADDR,
    TOKEN_CONTRACT_ADDR,
    BETTING_CONTRACT_DEPLOYED_BLOCK,
    ALCHEMY_KEY,
    ALCHEMY_NETWORK,
    POLYSCAN_TX_BASE,
    POLYSCAN_ADDR_BASE,
} = process.env.NODE_ENV === 'production' ? {
    NETWORK_CHAIN_ID: '0x89',
    BETTING_CONTRACT_ADDR: '0x6EA4D37149AEB5dDa366cC16c7CCbE49D7D7913C',
    TOKEN_CONTRACT_ADDR: '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472',
    BETTING_CONTRACT_DEPLOYED_BLOCK: 25894160,
    ALCHEMY_KEY: 'ECsqg_FtjJffOw_QkzfVQ1cpmNQPiNdj',
    ALCHEMY_NETWORK: 'matic',
    POLYSCAN_TX_BASE: 'https://polygonscan.com/tx/',
    POLYSCAN_ADDR_BASE: 'https://polygonscan.com/address/'
} : {
    NETWORK_CHAIN_ID: '0x13881',
    BETTING_CONTRACT_ADDR: '0x72bAb44DB92519e75187ad261D4D4dEA19Bc242c',
    TOKEN_CONTRACT_ADDR: '0x232134d1e47F95d1F5cFd7C1FfFE76BE99eA037C',
    BETTING_CONTRACT_DEPLOYED_BLOCK: 25433902,
    ALCHEMY_KEY: 'zBh7pWr6j400irGsZcJT5dAs_L0MV0nv',
    ALCHEMY_NETWORK: 'maticmum',
    POLYSCAN_TX_BASE: 'https://mumbai.polygonscan.com/tx/',
    POLYSCAN_ADDR_BASE: 'https://mumbai.polygonscan.com/address/'

};
